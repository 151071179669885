import React, { useState } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";

// data
import { auth } from "db/Firestore";

// functions
import { addDays } from "functions";

/**
 * The AuthForm is used to login into the application.
 * @param {Function} onClose What happens when the form is closed?
 */
const AuthForm = ({ onClose }) => {
  const [cookies, setCookie] = useCookies(["username"]);
  const [username, setUsername] = useState(cookies.username || "");
  const [password, setPassword] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    auth
      .signInWithEmailAndPassword(username, password)
      .then((res) => {
        setCookie("username", username, {
          path: "/",
          expires: addDays(30),
        });

        // reset state
        setPassword("");

        onClose();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <form className="mt-2" onSubmit={onSubmit}>
      <div className="auth-form-group p-rel">
        <input
          className="auth-input"
          name="username"
          onChange={(e) => setUsername(e.target.value)}
          placeholder="gebruikersnaam"
          type="text"
          value={username}
        />
        <FontAwesomeIcon className="auth-icon p-abs" icon={faUser} />
      </div>
      <div className="auth-form-group p-rel">
        <input
          className="auth-input"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="wachtwoord"
          type="password"
          value={password}
        />
        <FontAwesomeIcon className="auth-icon p-abs" icon={faLock} />
      </div>
      <button
        className="mt-2 btn btn-primary btn-auth rounded-sm"
        id="btn-auth-login"
        type="submit"
      >
        Inloggen
      </button>
    </form>
  );
};

AuthForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

AuthForm.defaultProps = {
  onClose: () => {
    console.log("closing auth form");
  },
};

export default AuthForm;
