import React, { useState, createContext } from "react";

// data
import { auth } from "db/Firestore";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  auth.onAuthStateChanged((state) => {
    setLoggedIn(state ? true : false);

    setTimeout(() => setIsLoading(false), 3000);
  });

  // set the state
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);

  return (
    <AuthContext.Provider value={isLoggedIn}>
      {isLoading ? (
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      ) : (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </AuthContext.Provider>
  );
};
