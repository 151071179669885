import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Board from "react-trello";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

// data
import { firestore } from "db/Firestore";

// context
import { AuthContext } from "context/AuthContext";
import { OverlayContext } from "context/OverlayContext";
import { PlanningContext } from "context/PlanningContext";

// functions
//import { getTimeString } from "functions";

// components
import CustomLaneHeader from "components/custom/CustomLaneHeader";
import CustomCard from "components/custom/CustomCard";

/**
 * The planningboard component is responsible for populating the trello boards with data.
 * @param {Function} onClick What happens when the header is clicked?
 * @param {Bool}     open    Whether or not the board is open.
 * @param {String}   title   The title of the board.
 * @param {Number}   type    Identifier for this board. (Used to load and save data.)
 */
const PlanningBoard = ({ onClick, open, title, type }) => {
  // set state
  const [boardData, setBoardData] = useState({ lanes: [] });

  // use context
  const auth = useContext(AuthContext);
  const { methods } = useContext(OverlayContext);
  const planning = useContext(PlanningContext);

  // load custom components to use in react-trello
  const components = {
    Card: CustomCard,
    LaneHeader: CustomLaneHeader,
  };

  // load board data
  useEffect(() => {
    // initial data
    const loadedData = {
      lanes: [],
    };

    planning.teams.forEach((team) => {
      loadedData.lanes.push({
        id: team.id,
        title: team.name,
        cards: [], // initial cards array
        target: type, // used to determine whether the card should be added on this board, or the other
      });
    });

    planning.tasks.forEach((task) => {
      if (task.type !== type) {
        return;
      }

      // get team id
      const teamId = task.team.id;

      // get corresponding lane
      const lane = loadedData.lanes.find((lane) => lane.id === teamId);

      //const start = getTimeString(task.start);
      //const stop = getTimeString(task.stop);

      // build card
      const _task = {
        id: task.id,
        title: task.client,
        //label: `${start} - ${stop}`,
        description: task.comments,
        metadata: {
          location: task.location,
          //start,
          ///stop,
          task_position: task.task_position,
          task_date: task.task_date,
          created_at: task.created_at,
          updated_at: task.updated_at,
        },
      };

      lane.cards.push(_task);
    });

    setBoardData(loadedData);
  }, [planning.teams, planning.tasks, type]);

  /**
   * Open the EditTaskOverlay when clicking on a card.
   * @param  {String} cardId   The card ID.
   * @param  {Object} metadata The metadata of this card.
   * @param  {String} laneId   The ID of the card's lane.
   */
  const editCard = (cardId, metadata, laneId) => {
    const lane = boardData.lanes.find((lane) => lane.id === laneId);
    const card = lane.cards.find((card) => card.id === cardId);

    // open the EditTaskOverlay with the card data
    methods.openEditTaskOverlay(cardId, card);
  };

  /**
   * Delete a card (when the delete icon has been clicked.)
   * @param  {String} cardId The card ID.
   * @param  {String} laneId The lane ID.
   */
  const deleteCard = (cardId, laneId) => {
    firestore.collection("tasks").doc(cardId).delete();
  };

  /**
   * Update a task when the card has been dragged to another list.
   * @param  {String} cardId       The ID of the card which has been dragged.
   * @param  {String} sourceLaneId The ID of the lane where the card came from.
   * @param  {String} targetLaneId The ID of the lane where the card has been dropped.
   * @param  {Number} position     The position in the list.
   * @param  {Object} cardDetails  Additional info about the card (title, metadata...).
   */
  const onDrag = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    // when the card is dragged across different lanes
    if (sourceLaneId !== targetLaneId) {
      moveAcrossLanes(cardId, sourceLaneId, targetLaneId, position);
      return;
    }

    // the card is dragged inside the lane
    moveInLane(cardId, sourceLaneId, position);
  };

  /**
   * Move a card inside a lane.
   * @param {String} cardId   The ID of the card which has been dragged.
   * @param {String} laneId   The ID of the lane on which the card has been dragged.
   * @param {Number} position The target position of the card.
   */
  const moveInLane = (cardId, laneId, position) => {
    const today = new Date();

    // get the lane in which the card is being moved
    const lane = boardData.lanes.find((lane) => lane.id === laneId);

    // get the card which is being moved
    const card = lane.cards.find((card) => card.id === cardId);

    // only update the cards if the card has actually changed position
    if (card.metadata.task_position !== position) {
      // insert the card at the correct position
      lane.cards.splice(
        position,
        0,
        lane.cards.splice(card.metadata.task_position, 1)[0]
      );

      // update the cards in the lane so they have the correct
      // position
      lane.cards.forEach((card, i) => {
        // only update the card if its position has changed
        if (card.metadata.task_position !== i) {
          firestore.collection("tasks").doc(card.id).update({
            task_position: i,
            updated_at: today,
          });
        }
      });
    }
  };

  /**
   * Move a card onto another lane.
   * @param {String} cardId   The ID of the card which has been dragged.
   * @param {String} from     The ID of the lane where the card came from.
   * @param {String} to       The ID of the lane where the card has been dropped.
   * @param {Number} position The target position of the card.
   */
  const moveAcrossLanes = (cardId, from, to, position) => {
    // get the current time
    const today = new Date();

    const from_lane = boardData.lanes.find((lane) => lane.id === from);
    const target_lane = boardData.lanes.find((lane) => lane.id === to);

    const card = from_lane.cards.find((card) => card.id === cardId);

    // no cards in target list yet
    if (!target_lane.cards.length) {
      firestore
        .collection("tasks")
        .doc(cardId)
        .update({
          team: firestore.doc(`/teams/${to}`),
          task_position: position,
          updated_at: today,
        })
        .then(update_remaining_cards(cardId, from_lane, today));

      return;
    }

    // already cards in the target list
    // don't change the target list directly
    const updated_target_lane = [...target_lane.cards];

    // insert the moved card in the target list
    updated_target_lane.splice(position, 0, card);

    updated_target_lane.forEach((card, i) => {
      // only update the card which has changed position
      if (card.metadata.task_position !== i || card.laneId !== to) {
        firestore
          .collection("tasks")
          .doc(card.id)
          .update({
            team: firestore.doc(`/teams/${to}`),
            task_position: i,
            updated_at: today,
          })
          .then(() => {
            // only perform updating on remaining cards in the from lane
            // if all cards in the target lane have been updated
            if (i === updated_target_lane.length - 1) {
              update_remaining_cards(cardId, from_lane, today);
            }
          });
      }
    });
  };

  /**
   * Update the remaining cards in the lane the card the came from.
   * @param {String} cardId The ID of the card which has been dragged.
   * @param {Object} from   The ID of the lane where the card came from.
   * @param {Date}   today  The time when the card is being updated.
   */
  const update_remaining_cards = (cardId, from, today = new Date()) => {
    // remove the moved card from the lane it came from
    const remaining_cards = from.cards.filter((card) => card.id !== cardId);

    // and update all cards left in that lane
    remaining_cards.forEach((card, i) => {
      if (card.metadata.task_position !== i) {
        firestore
          .collection("tasks")
          .doc(card.id)
          .update({
            team: firestore.doc(`/teams/${from.id}`),
            task_position: i,
            updated_at: today,
          });
      }
    });
  };

  return (
    <div className={`planningboard ${open ? "stretch" : ""}`}>
      <div className="planningboard-title" onClick={onClick}>
        <span className="planningboard-title-text disp-ib">{title}</span>
        <FontAwesomeIcon
          className="planningboard-title-icon"
          icon={open ? faAngleDown : faAngleUp}
        />
      </div>
      <Board
        cardDraggable={auth}
        className={open ? "" : "disp-n"}
        components={components}
        data={boardData}
        handleDragEnd={onDrag}
        onCardClick={auth ? editCard : null}
        onCardDelete={deleteCard}
      />
    </div>
  );
};

PlanningBoard.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
};

export default PlanningBoard;
