import React, { Fragment, useContext } from "react";

// context
import { PlanningContext } from "context/PlanningContext";

// components
import AddTeam from "./AddTeam";
import Team from "./team/Team";

const TeamsList = () => {
  const { teams } = useContext(PlanningContext);

  return (
    <Fragment>
      <div className="teams-list">
        {teams.map((team, index) => (
          <Team id={team.id} key={index} name={team.name} />
        ))}
      </div>
      <AddTeam />
    </Fragment>
  );
};

export default TeamsList;
