import React, { useContext } from "react";
import PropTypes from "prop-types";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// context
import { AuthContext } from "context/AuthContext";

/**
 * The OverlayHeader is a returning structure on the overlays which is used
 * to give information of the current active overlay.
 * @param {[type]} onClose  What happens when the overlay is closed?
 * @param {[type]} title    The title of the overlay.
 * @param {[type]} subtitle The subtitle of the overlay.
 */
const OverlayHeader = ({ onClose, title, subtitle }) => {
  const auth = useContext(AuthContext);

  return (
    <header>
      <div className="disp-f jc-sb">
        <h1 className="overlay-title">{title}</h1>
        {auth && (
          <FontAwesomeIcon
            className="overlay-close"
            icon={faTimes}
            onClick={onClose}
          />
        )}
      </div>
      <p className="overlay-subtitle">{subtitle}</p>
    </header>
  );
};

OverlayHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

OverlayHeader.defaultProps = {
  onClose: () => {
    console.log("Close overlayheader");
  },
  title: "[no-title-set]",
  subtitle: "[no-subtitle-set]",
};

export default OverlayHeader;
