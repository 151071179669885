import React, { useState } from "react";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

// db
import { firebase, firestore } from "db/Firestore";

const AddTeam = () => {
  const [name, setName] = useState("");

  const onAddTeam = (e) => {
    e.preventDefault();

    const now = firebase.firestore.FieldValue.serverTimestamp();

    firestore.collection("teams").add({
      name,
      created_at: now,
      updated_at: now,
    });

    setName("");
  };

  const onUpdate = (e) => {
    setName(e.target.value);
  };

  return (
    <div className="disp-f jc-sb add-team">
      <input
        name="name"
        onChange={onUpdate}
        type="text"
        value={name}
        maxLength="20"
      />
      <button
        className={`btn btn-secondary btn-add-team${
          name ? "" : "-disabled"
        } rounded-sm`}
        onClick={onAddTeam}
        disabled={name ? false : true}
      >
        <FontAwesomeIcon className="disp-ib icon-add" icon={faUserPlus} />
        Toevoegen
      </button>
    </div>
  );
};

export default AddTeam;
