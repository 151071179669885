import React, { useState, useContext } from "react";
import Calendar from "react-calendar";

import {
  faSignInAlt,
  faSignOutAlt,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";

// context
import { AuthContext } from "context/AuthContext";
import { DateContext } from "context/DateContext";
import { OverlayContext } from "context/OverlayContext";
import { PlanningContext } from "context/PlanningContext";

// components
import AuthOverlay from "components/layout/overlay/AuthOverlay";
import CollapsibleButton from "components/buttons/CollapsibleButton";
import TeamsOverlay from "components/layout/overlay/TeamsOverlay";
import SidebarToggle from "./SidebarToggle";
import Greeting from "components/layout/Greeting";

const Sidebar = () => {
  const [isOpen, setOpen] = useState(true);
  const auth = useContext(AuthContext);
  const date = useContext(DateContext);
  const overlay = useContext(OverlayContext);
  const planning = useContext(PlanningContext);

  /**
   * Set the open state of the sidebar.
   */
  const onClickState = () => {
    setOpen(!isOpen);
  };

  const onChangeDate = (d) => {
    date.methods.onChangeDate(d);

    // get tasks of the selected date
    planning.methods.getTasks(d);
  };

  return (
    <aside className={`sidebar sidebar-${isOpen ? "open" : "closed"} p-rel`}>
      {auth ? (
        <Calendar
          className={`${isOpen ? "" : "disp-n"}`}
          value={date.selected.value}
          onChange={onChangeDate}
        />
      ) : (
        <React.Fragment>{isOpen && <Greeting />}</React.Fragment>
      )}
      <SidebarToggle isOpen={isOpen} onClick={onClickState} />
      <div className={`p-abs sidebar-buttons${isOpen ? "" : "-closed"}`}>
        {auth && (
          <CollapsibleButton
            className={`btn-primary ${
              isOpen ? "mr-1 rounded-sm" : "disp-b rounded-lg"
            }`}
            collapse={!isOpen}
            icon={faUsersCog}
            label={"Teams"}
            onClick={() => overlay.methods.openTeamsOverlay()}
          />
        )}
        <CollapsibleButton
          className={`${auth ? "btn-outline" : "btn-primary"} ${
            isOpen ? "rounded-sm" : "disp-b mt-1 rounded-lg"
          }`}
          collapse={!isOpen}
          icon={auth ? faSignOutAlt : faSignInAlt}
          label={auth ? "Uitloggen" : "Inloggen"}
          onClick={() => overlay.methods.openAuthOverlay()}
        />
      </div>
      <TeamsOverlay
        isOpen={overlay.overlays.team}
        onClose={() => overlay.methods.closeOverlay("team")}
      />
      <AuthOverlay
        isOpen={overlay.overlays.auth || !auth}
        onClose={() => overlay.methods.closeOverlay("auth")}
      />
    </aside>
  );
};

export default Sidebar;
