import React from "react";
import PropTypes from "prop-types";

// components
import OverlayHeader from "./header/OverlayHeader";
import TeamsList from "components/teams/TeamsList";

/**
 * The TeamsOverlay can be used to add and edit teams.
 * @param {Boolean}   isOpen  Is the overlay open or not?
 * @param {Function}  onClose What should happen when the overlay is closed?
 */
const TeamsOverlay = ({ isOpen, onClose }) => {
  return (
    <div className={`overlay ${isOpen ? "" : "disp-n"}`}>
      <div className="overlay-content">
        <OverlayHeader
          onClose={onClose}
          title={"Teams"}
          subtitle={"Voeg hier je teams toe, bewerk en vewijder ze."}
        />
        <form className="mt-1 pb-1">
          <TeamsList />
        </form>
      </div>
    </div>
  );
};

TeamsOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

TeamsOverlay.defaultProps = {
  isOpen: false,
  onClose: () => {
    console.log("close teamsoverlay");
  },
};

export default TeamsOverlay;
