import React, { useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

// context
import { AuthContext } from "context/AuthContext";
import { DateContext } from "context/DateContext";
import { PlanningContext } from "context/PlanningContext";

const Header = () => {
  const auth = useContext(AuthContext);
  const { selected, methods } = useContext(DateContext);
  const planning = useContext(PlanningContext);

  const getPreviousDate = (date) => {
    methods.getPreviousDate(date);

    // get tasks of current date
    planning.methods.getTasks(date);
  };

  const getNextDate = (date) => {
    methods.getNextDate(date);

    // get tasks of current date
    planning.methods.getTasks(date);
  };

  return (
    <header className="planning-header">
      {auth && (
        <button
          className="planning-header-btn"
          onClick={() => getPreviousDate(selected.value)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      )}
      <div className="planning-header-date">
        <span>
          Dagplanning {selected.day.toLowerCase()} {selected.value.getDate()}{" "}
          {selected.month.toLowerCase()}
        </span>
      </div>
      {auth && (
        <button
          className="planning-header-btn"
          onClick={() => getNextDate(selected.value)}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      )}
    </header>
  );
};

export default Header;
