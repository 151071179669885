import React, { useState, createContext } from "react";

export const OverlayContext = createContext();

export const OverlayProvider = props => {
  const openTeamsOverlay = () => {
    setState(prevState => {
      return {
        ...prevState,
        overlays: {
          ...prevState.overlays,
          team: true
        }
      };
    });
  };

  const openAuthOverlay = () => {
    setState(prevState => {
      return {
        ...prevState,
        overlays: {
          ...prevState.overlays,
          auth: true
        }
      };
    });
  };

  /**
   * Open the add task overlay.
   * @param  {string} teamId    The id of the lane.
   * @param  {Number} boardType The board to add the task to.
   * @return {Object}           The new state.
   */
  const openAddTaskOverlay = (teamId, boardType) => {
    setState(prevState => {
      return {
        ...prevState,
        id: teamId,
        board: boardType,
        overlays: {
          ...prevState.overlays,
          addTask: true
        }
      };
    });
  };

  const openEditTaskOverlay = (taskId, task) => {
    setState(prevState => {
      return {
        ...prevState,
        id: taskId,
        card: task,
        overlays: {
          ...prevState.overlays,
          editTask: true
        }
      };
    });
  };

  const closeOverlay = overlay => {
    setState(prevState => {
      return {
        ...prevState,
        id: "",
        board: 0,
        card: {},
        overlays: {
          auth: overlay === "auth" ? false : prevState.overlays.auth,
          team: overlay === "team" ? false : prevState.overlays.team,
          addTask: overlay === "addTask" ? false : prevState.overlays.addTask,
          editTask: overlay === "editTask" ? false : prevState.overlays.editTask
        }
      };
    });
  };

  // set initial state
  const initialState = {
    id: "",
    board: 0,
    card: {},
    overlays: {
      auth: false,
      team: false,
      addTask: false,
      editTask: false
    },
    methods: {
      closeOverlay,
      openAuthOverlay,
      openAddTaskOverlay,
      openEditTaskOverlay,
      openTeamsOverlay
    }
  };

  // set the state
  const [state, setState] = useState(initialState);

  return (
    <OverlayContext.Provider value={state}>
      {props.children}
    </OverlayContext.Provider>
  );
};
