import React, { useState, createContext } from "react";

export const DateContext = createContext();

export const DateProvider = props => {
  /**
   * Get the string representation of the month.
   * @param  {Date} date The selected date.
   * @return {String}    The name of the month.
   */
  const getMonthString = date => {
    const months = [
      "Januari",
      "Februari",
      "Maart",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "December"
    ];
    const index = date.getMonth();

    return months[index];
  };

  /**
   * Get the string representation of the day.
   * @param  {Date} date             The selected date.
   * @param  {String} [type="short"] The way the date should be returned.
   * @return {String}                The name of the day.
   */
  const getDayString = (date, type = "short") => {
    const shortDays = ["ZO", "MA", "DI", "WO", "DO", "VR", "ZA"];
    const longDays = [
      "Zondag",
      "Maandag",
      "Dinsdag",
      "Woensdag",
      "Donderdag",
      "Vrijdag",
      "Zaterdag"
    ];

    const index = date.getDay();

    return type === "short" ? shortDays[index] : longDays[index];
  };

  /**
   * Change the date.
   * @param  {Date}   date The date to change to.
   * @return {Object}      The new state.
   */
  const onChangeDate = date => {
    setState(prevState => {
      return {
        ...prevState,
        selected: {
          value: date,
          day: getDayString(date),
          month: getMonthString(date)
        }
      };
    });
  };

  /**
   * Get the previous date.
   */
  const getPreviousDate = date => {
    // subtract one from the current date to get the date
    // before the current selected one
    const prevDate = new Date(date.setDate(date.getDate() - 1));

    // set the state
    setState(prevState => {
      return {
        ...prevState,
        selected: {
          value: prevDate,
          day: getDayString(prevDate),
          month: getMonthString(prevDate)
        }
      };
    });
  };

  /**
   * Get the next date.
   */
  const getNextDate = date => {
    // add one to the current date to get the date
    // after the current selected one
    const nextDate = new Date(date.setDate(date.getDate() + 1));

    // set the state
    setState(prevState => {
      return {
        ...prevState,
        selected: {
          value: nextDate,
          day: getDayString(nextDate),
          month: getMonthString(nextDate)
        }
      };
    });
  };

  // store the current date
  const today = new Date();

  // initial state
  const initialState = {
    methods: {
      getNextDate,
      getPreviousDate,
      onChangeDate
    },
    selected: {
      value: today,
      day: getDayString(today),
      month: getMonthString(today)
    }
  };

  // set the state
  const [state, setState] = useState(initialState);

  return (
    <DateContext.Provider value={state}>{props.children}</DateContext.Provider>
  );
};
