import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

// db
import { firestore } from "db/Firestore";

/**
 * The Team component is used to display the info about a team in the teamslist.
 * @param {String} id   The id of the team.
 * @param {String} name The name of the team.
 */
const Team = ({ id, name }) => {
  const [teamName, setTeamName] = useState(name);
  const [isInEditMode, setEditMode] = useState(false);
  const prevName = name;

  const activateEditMode = () => {
    setEditMode(true);
  };

  const cancelEditMode = () => {
    setTeamName(prevName);

    setEditMode(false);
  };

  const onSave = () => {
    if (teamName !== "") {
      if (id) {
        const today = new Date();

        firestore
          .collection("teams")
          .doc(id)
          .update({ name: teamName, updated_at: today });
      }
    }

    setEditMode(false);
  };

  const onUpdate = (e) => {
    setTeamName(e.target.value);
  };

  const onDelete = () => {
    if (id) {
      // get the team document
      const team = firestore.collection("teams").doc(id);

      // get all tasks which have a reference to the team
      firestore
        .collection("tasks")
        .where("team", "==", team)
        .get()
        .then((snapshot) => {
          for (const doc of snapshot.docs) {
            doc.ref.delete();
          }

          team.delete();
        });
    }
  };

  const renderEdit = () => {
    return (
      <Fragment>
        <input name="name" type="text" onChange={onUpdate} value={teamName} />
        <div role="list">
          <FontAwesomeIcon
            className="icon-edit"
            icon={faCheck}
            onClick={onSave}
          />
          <FontAwesomeIcon
            className="icon-delete"
            icon={faTimes}
            onClick={cancelEditMode}
          />
        </div>
      </Fragment>
    );
  };

  const renderDefault = () => {
    return (
      <Fragment>
        <label onDoubleClick={activateEditMode}>{name}</label>
        <div role="list">
          <FontAwesomeIcon
            className="icon-edit"
            icon={faEdit}
            onClick={activateEditMode}
          />
          <FontAwesomeIcon
            className="icon-delete"
            icon={faTrashAlt}
            onClick={onDelete}
          />
        </div>
      </Fragment>
    );
  };

  return (
    <div className="team disp-f jc-sb">
      {isInEditMode ? renderEdit() : renderDefault()}
    </div>
  );
};

Team.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

Team.defaultProps = {
  id: "",
  name: "[no-name-set]",
};

export default Team;
