import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyClCq_CnfQ6tN1bowErzA62GmkiMZ-r4yQ",
  authDomain: "boringen-schollaert.firebaseapp.com",
  databaseURL: "https://boringen-schollaert.firebaseio.com",
  projectId: "boringen-schollaert",
  storageBucket: "boringen-schollaert.appspot.com",
  messagingSenderId: "423787123626",
  appId: "1:423787123626:web:06596831960054733211d3",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

export { auth, firebase, firestore };
