import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

/**
 * The SidebarToggle is used to determine the state of the sidebar.
 * @param {Boolean} isOpen  Whether or not the sidebar is opened.
 * @param {[type]}  onClick What happens when the toggle is clicked?
 */
const SidebarToggle = ({ isOpen, onClick }) => {
  return (
    <button
      className={`sidebar-toggle sidebar-toggle-${
        isOpen ? "close" : "open"
      } p-abs`}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleRight} />
    </button>
  );
};

SidebarToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

SidebarToggle.defaultProps = {
  isOpen: false,
  onClick: () => {
    console.log("clicked sidebartoggle");
  },
};

export default SidebarToggle;
