import React from "react";
import PropTypes from "prop-types";

// data
import { auth } from "db/Firestore";

/**
 * The logout form is used to log out of the application.
 * @param {Function} onClose What happens when the form is closed?
 */
const LogoutForm = ({ onClose }) => {
  const onSubmit = (e) => {
    e.preventDefault();

    // signout the user from firebase
    auth.signOut();

    // close the overlay
    onClose();
  };

  return (
    <form className="mt-2" onSubmit={onSubmit}>
      <button
        className="btn btn-primary btn-auth rounded-sm"
        id="btn-auth-logout"
        type="submit"
      >
        Uitloggen
      </button>
    </form>
  );
};

LogoutForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

LogoutForm.defaultProps = {
  onClose: () => {
    console.log("close logout form");
  },
};

export default LogoutForm;
