import React, { useState, useEffect, createContext, useContext } from "react";
import { firestore } from "db/Firestore";

import { AuthContext } from "./AuthContext";

export const PlanningContext = createContext();

export const PlanningProvider = (props) => {
  const auth = useContext(AuthContext);

  useEffect(() => {
    console.log("ik ben hier");
    console.log(auth);
    // get teams data
    getTeams();

    if (auth) {
      // get tasks data
      getTasks();
    }
  }, [auth]);

  const getTeams = () => {
    firestore
      .collection("teams")
      .orderBy("created_at")
      .onSnapshot((snapshot) => {
        const teamsData = [];

        snapshot.forEach((doc) => {
          const id = doc.id,
            data = doc.data();

          teamsData.push({ id, ...data });
        });

        setState((prevState) => {
          return {
            ...prevState,
            teams: teamsData,
          };
        });
      });
  };

  const getTasks = (date = new Date()) => {
    // set hours to midnight of the given date
    date.setHours(0, 0, 0, 0);

    const todays_tasks = firestore
      .collection("tasks")
      .where("task_date", "==", date)
      .orderBy("task_position", "asc");

    todays_tasks.onSnapshot((snapshot) => {
      const tasksData = [];

      snapshot.forEach((doc) => {
        const id = doc.id,
          data = doc.data();

        tasksData.push({ id, ...data });
      });

      setState((prevState) => {
        return {
          ...prevState,
          tasks: tasksData,
        };
      });
    });
  };

  const initialState = {
    teams: [],
    tasks: [],
    methods: {
      getTasks,
    },
  };

  const [state, setState] = useState(initialState);

  return (
    <PlanningContext.Provider value={state}>
      {props.children}
    </PlanningContext.Provider>
  );
};
