import React from "react";

// components
import Header from "./Header";
import Boards from "components/layout/boards/Boards";

const Planning = () => {
  return (
    <main className="planning">
      <Header />
      <Boards />
    </main>
  );
};

export default Planning;
