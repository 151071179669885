import React from "react";

const Greeting = () => {
  return (
    <div className="pl-1 pt-2">
      <h1 className="overlay-title">Planningstool</h1>
      <p className="overlay-subtitle">
        Meld je aan om de planning te bekijken.
      </p>
    </div>
  );
};

export default Greeting;
