import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";

// data
import { firebase, firestore } from "db/Firestore";

// context
// import { DateContext } from "context/DateContext";
import { OverlayContext } from "context/OverlayContext";

// functions
// import { getDate } from "functions";

// components
import OverlayHeader from "./header/OverlayHeader";
import TaskForm from "components/forms/TaskForm";

/**
 * The EditTaskOverlay can be used to edit tasks.
 * @param {String}    id      The id of the task.
 * @param {Boolean}   isOpen  Is the overlay open or not?
 * @param {Function}  onClose What should happen when the overlay is closed?
 * @param {Object}    task    The task to edit.
 */
const EditTaskOverlay = ({ id, isOpen, onClose, task }) => {
  /**
   * Disable start and stop time
   * const date = useContext(DateContext);
   */
  const overlay = useContext(OverlayContext);

  /**
   * Save the task.
   * @param  {SubmitEvent} e The event data.
   * @param  {Object}      t The new task data.
   */
  const onSaveTask = (e, t) => {
    e.preventDefault();

    const { client, location, comments } = t;

    // get the current time
    const updated_at = firebase.firestore.FieldValue.serverTimestamp();

    // get datetimes
    /**
     * Disable start and stop time
     * const startDateTime = getDate(date.selected.value, start);
     * const stopDateTime = getDate(date.selected.value, stop);
     **/

    const _task = {
      client,
      location,
      /**start: startDateTime,
      stop: stopDateTime,**/
      comments,
      updated_at,
    };

    // add task to database
    firestore.collection("tasks").doc(id).update(_task);

    overlay.methods.closeOverlay("editTask");
  };

  return (
    <div className={`overlay ${isOpen ? "" : "disp-n"}`}>
      <div className="overlay-content">
        <OverlayHeader
          onClose={onClose}
          title={"Taak bewerken"}
          subtitle={
            <Fragment>
              Bewerk taak "<span className="fw-b">{task.title}</span>".
            </Fragment>
          }
        />
        <TaskForm isOpen={isOpen} onSubmit={onSaveTask} task={task} />
      </div>
    </div>
  );
};

EditTaskOverlay.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
};

EditTaskOverlay.defaultProps = {
  id: "",
  isOpen: false,
  onClose: () => {
    console.log("Close editTaskOverlay");
  },
  task: {},
};

export default EditTaskOverlay;
