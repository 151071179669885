import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  MovableCardWrapper,
  CardHeader,
  CardRightContent,
  CardTitle,
  Detail,
} from "react-trello/dist/styles/Base";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

// context
import { AuthContext } from "context/AuthContext";

/**
 * The custom card determines the look and feel of a card in react trello.
 * @param {Bool}     showDeleteButton Whether or not the delete button should be showed.
 * @param {Object}   style            The style of the card.
 * @param {Object}   tagStyle         The style of the tags.
 * @param {Function} onClick          What should happen when the card is clicked?
 * @param {Function} onDelete         What should happen when the card is deleted from the board?
 * @param {String}   className        Extra classes to append to the card.
 * @param {String}   id               The id of the card.
 * @param {String}   title            The card's title.
 * @param {String}   label            The label on the card.
 * @param {String}   description      The description on the card.
 * @param {Object}   metadata         Extra metadata to set on the card.
 * @param {Bool}     cardDraggable    Whether or not the card is draggable.
 */
const CustomCard = ({
  showDeleteButton,
  style,
  tagStyle,
  onClick,
  onDelete,
  className,
  id,
  title,
  label,
  description,
  metadata,
  cardDraggable,
}) => {
  const auth = useContext(AuthContext);

  /**
   * Execute the onDelete functionality of the card.
   * @param  {MouseEvent} e The current event.
   */
  const deleteCard = (e) => {
    e.preventDefault();
    onDelete();
    e.stopPropagation();
  };

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={onClick}
      style={style}
      className={className}
    >
      <CardHeader>
        <CardTitle draggable={cardDraggable}>
          {title}
          <span className="disp-b fw-n wrap">{metadata.location}</span>
        </CardTitle>
        <CardRightContent>{label}</CardRightContent>
        {auth && (
          <FontAwesomeIcon
            className="icon-delete"
            icon={faTrashAlt}
            onClick={deleteCard}
          />
        )}
      </CardHeader>
      <Detail>{description}</Detail>
    </MovableCardWrapper>
  );
};

CustomCard.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
};

CustomCard.defaultProps = {
  showDeleteButton: false,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  tagStyle: {},
  title: "No title",
  description: "",
  label: "",
  tags: [],
  className: "",
};

export default CustomCard;
