import React, { useState, useContext } from "react";

// context
import { OverlayContext } from "context/OverlayContext";

// components
import AddTaskOverlay from "components/layout/overlay/AddTaskOverlay";
import EditTaskOverlay from "components/layout/overlay/EditTaskOverlay";
import PlanningBoard from "./PlanningBoard";

const Boards = () => {
  const [boardOneState, setBoardOneState] = useState(false);
  const [boardTwoState, setBoardTwoState] = useState(true);
  const overlay = useContext(OverlayContext);

  return (
    <div className="boards">
      <PlanningBoard
        onClick={e => setBoardOneState(!boardOneState)}
        open={boardOneState}
        title="Slijp- en Kapwerken"
        type={1}
      />
      <PlanningBoard
        onClick={e => setBoardTwoState(!boardTwoState)}
        open={boardTwoState}
        title="Boringen"
        type={2}
      />
      <AddTaskOverlay
        id={overlay.id}
        isOpen={overlay.overlays.addTask}
        onClose={() => overlay.methods.closeOverlay("addTask")}
        type={overlay.board}
      />
      <EditTaskOverlay
        id={overlay.id}
        isOpen={overlay.overlays.editTask}
        onClose={() => overlay.methods.closeOverlay("editTask")}
        task={overlay.card}
      />
    </div>
  );
};

export default Boards;
