import React, { useContext } from "react";
import PropTypes from "prop-types";

// context
import { AuthContext } from "context/AuthContext";

// components
import AuthForm from "components/forms/AuthForm";
import LogoutForm from "components/forms/LogoutForm";
import OverlayHeader from "./header/OverlayHeader";

/**
 * The AuthOverlay is used to hold the forms responsible for logging in and out
 * of the application.
 * @param {Boolean}   isOpen  Is the overlay open or not?
 * @param {Function}  onClose What should happen when the overlay is closed?
 */
const AuthOverlay = ({ isOpen, onClose }) => {
  const auth = useContext(AuthContext);

  return (
    <div className={`overlay ${isOpen ? "" : "disp-n"}`}>
      <div className="overlay-content">
        <OverlayHeader
          onClose={onClose}
          title={auth ? "Uitloggen" : "Login"}
          subtitle={
            auth
              ? "Ben je zeker dat je wilt uitloggen?"
              : "Log hier in op je account."
          }
        />
        {auth ? (
          <LogoutForm onClose={onClose} />
        ) : (
          <AuthForm onClose={onClose} />
        )}
      </div>
    </div>
  );
};

AuthOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AuthOverlay.defaultProps = {
  isOpen: false,
  onClose: () => {
    console.log("close authoverlay");
  },
};

export default AuthOverlay;
