import React, { useContext } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// context
import { AuthContext } from "context/AuthContext";
import { OverlayContext } from "context/OverlayContext";

/**
 * The customLaneHeader determines the look and feel of the lane header in react trello.
 * @param {String} id      The ID of the lane.
 * @param {String} label   The label of the lane.
 * @param {Array}  cards   The cards in the lane.
 * @param {String} title   The title of the lane.
 * @param {Object} current The current selected lane.
 * @param {Object} target  The target object.
 */
const CustomLaneHeader = ({ id, label, cards, title, current, target }) => {
  const auth = useContext(AuthContext);
  const overlay = useContext(OverlayContext);

  /**
   * Open the AddTaskOverlay when the add task button is clicked.
   */
  const addCard = () => {
    overlay.methods.openAddTaskOverlay(id, target);
  };

  return (
    <div className="disp-f jc-sb">
      <span>{title}</span>
      {auth && (
        <button
          className="btn btn-primary rounded-lg"
          onClick={addCard}
          title="Taak toevoegen"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      )}
    </div>
  );
};

CustomLaneHeader.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  cards: PropTypes.array,
  title: PropTypes.string.isRequired,
  current: PropTypes.object,
  target: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
};

CustomLaneHeader.defaultProps = {
  id: "",
  label: "",
  cards: [],
  title: "no title",
  current: {},
  target: {}
};

export default CustomLaneHeader;
