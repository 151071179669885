import React, { /*useContext,*/ useEffect, useState } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

// context
// import { DateContext } from "context/DateContext";

// components
import FormInput from "./FormInput";

/**
 * The task form can be used to add and edit tasks.
 * @param {Boolean}  isOpen     Whether or not the form is open.
 * @param {Function} onSubmit   What happens when the form is submitted?
 * @param {Object}   task       The taskdata which is being submitted.
 */
const TaskForm = ({ isOpen, onSubmit, task }) => {
  const [client, setClient] = useState("");
  const [location, setLocation] = useState("");
  //const [start, setStart] = useState("");
  //const [stop, setStop] = useState("");
  const [comments, setComments] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (task) {
      const { title, description, metadata } = task;

      setClient(title ? title : "");
      setLocation(metadata ? metadata.location : "");
      //setStart(metadata ? metadata.start : "");
      //setStop(metadata ? metadata.stop : "");
      setComments(description ? description : "");
    }

    if (!isOpen) {
      onClose();
    }
  }, [isOpen, task]);

  const beforeSubmit = (e) => {
    e.preventDefault();

    // validation
    let isValid = false;
    const errMsg = "Dit veld is verplicht";
    const err = {};

    if (!client || client === "") {
      err.client = errMsg;
    }

    if (!location || location === "") {
      err.location = errMsg;
    }

    // check if errors have been set
    isValid = Object.keys(err).length ? false : true;

    // validation passed
    // proceed saving
    if (isValid) {
      onSubmit(e, { client, location, /*start, stop,*/ comments });
    }

    // set errors state
    setErrors(err);
  };

  const onClose = () => {
    setClient("");
    setLocation("");
    //setStop("");
    setComments("");
    setErrors({});
  };

  return (
    <form className="overlay-form pb-1" onSubmit={beforeSubmit}>
      <div className="form-container">
        <div className="disp-f mb-1">
          <FormInput
            error={errors.client}
            label={"Klant"}
            name={"client"}
            onChange={(e) => setClient(e.target.value)}
            required={true}
            type={"text"}
            value={client}
          />

          <FormInput
            error={errors.location}
            label={"Locatie"}
            name={"location"}
            onChange={(e) => setLocation(e.target.value)}
            required={true}
            type={"text"}
            value={location}
          />
        </div>

{/*         <div className="disp-f mb-1">
          <FormInput
            error={errors.start}
            label={"Start uur"}
            name={"start"}
            onChange={(e) => setStart(e.target.value)}
            required={true}
            type={"time"}
            value={start}
          />

          <FormInput
            error={errors.stop}
            label={"Stop uur"}
            name={"stop"}
            onChange={(e) => setStop(e.target.value)}
            required={true}
            type={"time"}
            value={stop}
          />
        </div> */}

        <FormInput
          className={"comments"}
          label={"Opmerkingen"}
          name={"comments"}
          onChange={(e) => setComments(e.target.value)}
          type={"text"}
          value={comments}
        />
      </div>
      <button
        className="btn btn-primary btn-overlay-save rounded-sm"
        name="submit"
        type="submit"
      >
        <FontAwesomeIcon className="btn-icon" icon={faSave} />
        Opslaan
      </button>
    </form>
  );
};

TaskForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  task: PropTypes.object,
};

TaskForm.defaultProps = {
  isOpen: false,
  onSubmit: () => {
    console.log("Submit task data");
  },
  task: null,
};

export default TaskForm;
