import React, { useContext } from "react";
import PropTypes from "prop-types";

// data
import { firebase, firestore } from "db/Firestore";

// context
import { DateContext } from "context/DateContext";
import { OverlayContext } from "context/OverlayContext";
import { PlanningContext } from "context/PlanningContext";

// functions
// import { getDate } from "functions";

// components
import OverlayHeader from "./header/OverlayHeader";
import TaskForm from "components/forms/TaskForm";

/**
 * The AddTaskOverlay can be used to add tasks.
 * @param {String}    id      The id of the lane to add the card to.
 * @param {Boolean}   isOpen  Is the overlay open or not?
 * @param {Function}  onClose What should happen when the overlay is closed?
 * @param {Number}    type    The id of the board (where the card should be added).
 */
const AddTaskOverlay = ({ id, isOpen, onClose, type }) => {
  // use the context
  const date = useContext(DateContext);
  const overlay = useContext(OverlayContext);
  const planning = useContext(PlanningContext);

  /**
   * Save the task.
   * @param  {MouseEvent} e The current event.
   * @param  {Object}     t The (submitted) task data.
   */
  const onSaveTask = (e, t) => {
    e.preventDefault();

    // destructure values
    const { client, location, comments } = t;
    const created_at = firebase.firestore.FieldValue.serverTimestamp();

    // get datetimes
    //const startDateTime = getDate(date.selected.value, start);
    //const stopDateTime = getDate(date.selected.value, stop);

    // get all tasks for the current selected date (found in context)
    // filter on the type and the team to which this task is being added
    const tasks = planning.tasks.filter((task) => {
      return task.type === type && task.team.id === id;
    });

    // if there are already tasks defined for this team
    // add one to its position, otherwise just return the length
    const task_position = tasks.length;

    // set the task date to the current selected date
    const task_date = date.selected.value;
    task_date.setHours(0, 0, 0, 0);

    // prepare the task
    const task = {
      client,
      location,
      //start: startDateTime,
      //stop: stopDateTime,
      comments,
      team: firestore.doc(`/teams/${id}`),
      task_position,
      task_date,
      type,
      created_at,
      updated_at: created_at,
    };

    // add task to database
    firestore.collection("tasks").add(task);

    overlay.methods.closeOverlay("addTask");
  };

  return (
    <div className={`overlay ${isOpen ? "" : "disp-n"}`}>
      <div className="overlay-content">
        <OverlayHeader
          onClose={onClose}
          title={"Nieuwe taak"}
          subtitle={"Voeg een nieuwe taak toe."}
        />
        <TaskForm isOpen={isOpen} onSubmit={onSaveTask} />
      </div>
    </div>
  );
};

AddTaskOverlay.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
};

AddTaskOverlay.defaultProps = {
  id: "",
  isOpen: false,
  onClose: () => {
    console.log("Close addTaskOverlay");
  },
  type: 1,
};

export default AddTaskOverlay;
