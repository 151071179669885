import React from "react";
import { CookiesProvider } from "react-cookie";

// context
import { AuthProvider } from "./context/AuthContext";
import { DateProvider } from "./context/DateContext";
import { OverlayProvider } from "./context/OverlayContext";
import { PlanningProvider } from "./context/PlanningContext";

// components
import Sidebar from "./components/layout/sidebar/Sidebar";
import Planning from "./components/layout/planning/Planning";

function App() {
  return (
    <CookiesProvider>
      <AuthProvider>
        <DateProvider>
          <OverlayProvider>
            <PlanningProvider>
              <div className="App">
                <Sidebar />
                <Planning />
              </div>
            </PlanningProvider>
          </OverlayProvider>
        </DateProvider>
      </AuthProvider>
    </CookiesProvider>
  );
}

export default App;
