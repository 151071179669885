import React from "react";
import PropTypes from "prop-types";

/**
 * A FormInput consists of an input and a label which can be used in any form.
 * @param {String}   className Determine the look and feel of the inputfield with extra classes.
 * @param {String}   id        The id of the inputfield (optional).
 * @param {Boolean}  disabled  Whether or not this inputfield is disabled.
 * @param {String}   error     The error message to show with this inputfield.
 * @param {String}   label     The textcontent of the label.
 * @param {String}   name      The name of this inputfield.
 * @param {Function} onChange  What happens when the value of the inputfield is changing?
 * @param {String}   type      The type of input.
 * @param {Boolean}  required  Whether or not this inputfield is required.
 * @param {String}   value     The value of the inputfield.
 */
const FormInput = ({
  className,
  id,
  disabled,
  error,
  label,
  name,
  onChange,
  type,
  required,
  value,
}) => {
  return (
    <div className="form-group">
      <label>
        {label}
        {required && <span className="required">*</span>}
        <input
          className={`${className ? className : ""} ${error ? "invalid" : ""}`}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          required={required}
          type={type}
          value={value}
        />
        {error && <span className="error-message">{error}</span>}
      </label>
    </div>
  );
};

FormInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

FormInput.defaultProps = {
  disabled: false,
  error: "",
  label: "[no-label-set]",
  name: "[no-name-set]",
  onChange: () => {
    console.log("changing value of input");
  },
  type: "text",
  required: false,
  value: "",
};

export default FormInput;
