import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * A button which can collapse based on a boolean value.
 * @param {String}   className Determine the look and feel with extra classNames.
 * @param {Boolean}  collapse  Should the button be collapsed?
 * @param {Object}   icon      The icon on the button (optional).
 * @param {String}   label     The text on the button.
 * @param {Function} onClick   What happens when this button is clicked?
 */
const CollapsibleButton = ({ className, collapse, icon, label, onClick }) => {
  return (
    <button
      className={`btn ${
        collapse ? "btn-collapsed" : "btn-collapsible"
      } ${className}`}
      onClick={onClick}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={`${collapse ? "" : "btn-icon"}`}
        />
      )}
      <span className={`${collapse ? "disp-n" : ""}`}>{label}</span>
    </button>
  );
};

CollapsibleButton.propTypes = {
  className: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
  icon: PropTypes.object,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CollapsibleButton;
