/**
 * Check if a given date is in the past.
 * @param  {Date} d The date to check.
 * @return {bool}   True if the date is past, false otherwise.
 */
export const checkPastDate = d => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return d < today;
};

/**
 * Split a timestring.
 * @param  {String} t The timestring to split.
 * @return {Array}   An array containing the separated hour and minute values.
 * @usage  onSaveTask
 */
const splitTime = t => {
  return t.split(":");
};

/**
 * Get date based on string value.
 * @param  {Date}   d The selected date.
 * @param  {String} v The time of the task.
 * @return {Date}     The datetime string.
 * @usage  onSaveTask
 */
export const getDate = (d, v) => {
  // split the given time
  // and set the hours of the date
  const tVal = splitTime(v);
  const hours = d.setHours(tVal[0], tVal[1], 0);

  // convert to a datetime string
  const datetime = new Date(hours);

  return datetime;
};

/**
 * Add leading zeros.
 * @param  {String} v The time string.
 * @return {String}   The updated time string.
 * @usage onGetTasks
 */
export const pad = v => {
  return v < 10 ? "0" + v : v;
};

/**
 * Get the timestring from a given timestamp.
 * @param  {Timestamp} v The timestamp from the database.
 * @return {String}      The timestring.
 * @usage onGetTasks
 */
export const getTimeString = v => {
  const d = v.toDate();

  return `${pad(d.getHours())}:${pad(d.getMinutes())}`;
};

/**
 * Add days to a given date.
 * @param {Number} days        The number of days to add.
 * @param {Date}   [date=null] The date to add the days to.
 */
export const addDays = (days, date = null) => {
  const result = date ? new Date(date) : new Date();
  result.setDate(result.getDate() + days);

  return result;
};
